<template>
  <div class="mod-config">
    <el-form :inline="true" :model="dataForm">
      <el-form-item>
        <el-input
          v-model="dataForm.carNumber"
          placeholder="输入车牌号搜索"
          clearable
        ></el-input>
      </el-form-item>

      <el-form-item>
        <el-input
          v-model="dataForm.employeeName"
          placeholder="输入姓名"
          clearable
        ></el-input>
      </el-form-item>

      <el-form-item>
        <el-select
          v-model="dataForm.type"
          clearable
          filterable
          placeholder="选择能源类型"
        >
          <el-option
            v-for="item in carNylxOpt"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-date-picker
          value-format="yyyy-MM"
          v-model="dataForm.month"
          type="month"
          placeholder="选择月"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button @click="getDataList()">查询</el-button>
        <el-button type="primary" @click="addOrUpdateHandle()">新增</el-button>
      </el-form-item>
      <el-form-item>
        <download-excel
          class="export-excel-wrapper"
          :fetch="fetchData"
          :fields="excel_fields"
          :header="excelTitle"
          :name="excelName"
        >
          <!-- 上面可以自定义自己的样式，还可以引用其他组件button -->
          <el-button type="success">导出</el-button>
        </download-excel>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="medium"
      v-loading="dataListLoading"
      style="width: 100%"
    >
      <el-table-column
        prop="id"
        header-align="center"
        align="center"
        label="ID"
      >
      </el-table-column>
      <el-table-column
        prop="employeeName"
        header-align="center"
        align="center"
        label="员工"
      >
      </el-table-column>
      <el-table-column
        prop="carNumber"
        header-align="center"
        align="center"
        label="车牌号"
      >
      </el-table-column>
      <el-table-column
        prop="type"
        header-align="center"
        align="center"
        label="能源类型"
      >
        <template slot-scope="scope"
          >{{
            carNylxOpt.find((item) => item.value + "" === scope.row.type + "")
              .name || ""
          }}
          <span v-if="scope.row.gasolineType">{{
            "(" + scope.row.gasolineType + ")"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="unit"
        header-align="center"
        align="center"
        label="数量"
      >
        <template slot-scope="scope">{{
          scope.row.unit + ["升", "度", "立方米", "升"][scope.row.type]
        }}</template>
      </el-table-column>
      <el-table-column
        prop="money"
        header-align="center"
        align="center"
        label="金额(元)"
      >
      </el-table-column>
      <el-table-column
        prop="currentMileage"
        header-align="center"
        align="center"
        label="里程数(公里)"
      >
      </el-table-column>
      <el-table-column
        prop="supplementDate"
        header-align="center"
        align="center"
        label="加油/充电时间"
      >
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            @click="addOrUpdateHandle(scope.row.id)"
            >修改</el-button
          >
          <el-button
            type="danger"
            size="mini"
            @click="deleteHandle(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="text-align: center; margin-top: 10px"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100, 1000, 10000]"
      :page-size="pageSize"
      :total="totalCount"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>
  </div>
</template>

<script>
import AddOrUpdate from "./carenergy-add-or-update";

import { carNylx } from "@/assets/json/index";

export default {
  data() {
    return {
      carNylxOpt: carNylx,
      excelName: "能源记录.xls",
      excelTitle: "能源记录表",
      excel_fields: {
        序号: "numIndex",
        车牌号: "carNumber",
        员工: "employeeName",
        能源类型: "type",
        数量: "unit",
        金额: "money",
        里程数: "currentMileage",
        时间: "supplementDate",
      },
      dataForm: {
        carNumber: "",
        type: "",
        employeeName: "",
        month: "",
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
    };
  },
  components: {
    AddOrUpdate,
  },
  created() {
    this.getDataList();
  },
  methods: {
    // 封装excel数据
    async fetchData() {
      this.excelName = this.dataForm.carNumber + "能源记录.xls";
      this.excelTitle = this.dataForm.carNumber + "能源记录表";
      let request = {
        page: 1,
        limit: -1,
        carNumber: this.dataForm.carNumber,
        month: this.dataForm.month,
      };

      let res = await this.$http.carenergy.list(request);
      if (!(res?.data?.list?.length > 0)) this.$message.error("暂无数据");

      if (res && res.code === 0) {
        let dataList = res.data.list;
        let arr = [];
        for (let i = 0; i < dataList.length; i++) {
          let item = dataList[i];
          let excelItem = {
            numIndex: i + 1 + "",
            carNumber: item.carNumber,
            employeeName: item.employeeName,
            money: item.money + "元",
            currentMileage: item.currentMileage + "公里",
            supplementDate: item.supplementDate,
          };
          if (item.type == 0) {
            excelItem.type = "加" + item.gasolineType + "汽油";
            excelItem.unit = item.unit + "升";
          } else if (item.type == 2) {
            excelItem.type = "加气";
            excelItem.unit = item.unit + "立方米";
          } else if (item.type == 3) {
            excelItem.type = "加柴油";
            excelItem.unit = item.unit + "升";
          } else {
            excelItem.type = "充电";
            excelItem.unit = item.unit + "度";
          }
          arr.push(excelItem);
        }
        return arr;
      } else {
        return [];
      }
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      this.dataForm.page = this.pageIndex;
      this.dataForm.limit = this.pageSize;

      this.$http.carenergy.list(this.dataForm).then((res) => {
        if (res && res.code === 0) {
          this.dataList = res.data.list;
          this.totalCount = res.data.totalCount;
        } else {
          this.dataList = [];
          this.totalCount = 0;
        }
        this.dataListLoading = false;
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    // 删除
    deleteHandle(id) {
      this.$confirm(`确定要进行删除吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http.carenergy
          .delete({
            id,
          })
          .then((res) => {
            if (res && res.code === 0) {
              this.$message({
                message: res.msg,
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.getDataList();
                },
              });
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.mod-config {
  padding: 15px;
}
::v-deep .el-table__fixed-right {
  height: 100% !important;
}
</style>